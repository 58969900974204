import React, { FC, useState, useEffect } from 'react'
import { usePopper } from 'react-popper'

interface DropdownMenuT {
  trigger: any
  children: React.ReactNode
}

const DropdownMenu: FC<DropdownMenuT> = ({ trigger, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  })

  return (
    <div className="dropdown" ref={setReferenceElement}>
      <div className="dropdown-trigger" onClick={() => setIsOpen(!isOpen)}>
        {trigger}
      </div>
      <div
        className="dropdown-popup"
        ref={setPopperElement}
        data-show={isOpen}
        {...attributes.popper}
        style={styles.popper}
      >
        {children}
      </div>
    </div>
  )
}

export default DropdownMenu
