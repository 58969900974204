import { Link, PageProps } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import site from '../../_data/site.json'
import DropdownMenu from '../../components/dropdown-menu'

import ChevronDown from '../../images/chevron-down.svg'
import { SVG, easing } from '@svgdotjs/svg.js'
import useElementSize from '../../hooks/useElementSize'
import getAbsoluteOffset from '../../utils/getAbsoluteOffset'
import useHeaderLines from '../../hooks/useHeaderLines'

const OurServices: React.FC<PageProps> = ({ location }) => {
  const { workplaceStrategy } = site.pages.services
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--our-services'),
      )
      const preIntroRect = getAbsoluteOffset(
        document.querySelector('.pre-intro'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const polyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          preIntroRect.bottom,

          pageRect.right,
          preIntroRect.bottom,

          pageRect.right,
          pageRect.bottom - pageRect.height / 4,

          document.body.clientWidth,
          pageRect.bottom - pageRect.height / 4,
        ])
        .stroke({
          width: 0,
        })
      const polylineLength = polyline.node.getTotalLength()
      polyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': polylineLength,
          'stroke-dasharray': polylineLength,
        })
        .animate({ duration: 3000, delay: 0 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout>
      <SEO title="Workplace Strategy" />
      <div className="pre-intro" style={{ height: 50 }}></div>
      <div ref={pageRef} className="container page--our-services">
        <AsideMenu links={site.navigation.services} location={location} />
        <main className="subpage subpage--workplace-strategy">
          <img className="subpage__image" src={workplaceStrategy.image} />
          <h1 className="subpage__title">{workplaceStrategy.heading}</h1>
          <div
            className="subpage__content"
            dangerouslySetInnerHTML={{
              __html: workplaceStrategy.content,
            }}
          />
        </main>
      </div>
    </Layout>
  )
}

export default OurServices

interface AsideMenuT {
  links: Array<{ url: string; label: string }>
  location: any
}

export const AsideMenu: React.FC<AsideMenuT> = ({ links, location }) => {
  const { current } = location.state || 'Workplace Strategy'
  return (
    <aside className="aside aside--our-services">
      <div className="mobile-nav">
        <DropdownMenu
          trigger={
            <>
              <span className="dropdown-trigger__label">
                {current === undefined ? 'Workplace Strategy' : current}
              </span>
              <ChevronDown className="dropdown-trigger__icon" />
            </>
          }
        >
          <nav className="dropdown-nav">
            {links.map(
              (item: { url: string; label: string }, index: number) => {
                return (
                  <Link
                    className="dropdown-nav__link"
                    activeClassName="dropdown-nav__link--current"
                    key={index}
                    to={item.url}
                    state={{ current: item.label }}
                  >
                    <span className="dropdown-nav__link-label">
                      {item.label}
                    </span>
                  </Link>
                )
              },
            )}
          </nav>
        </DropdownMenu>
      </div>
      <nav className="nav">
        {links.map((item: { url: string; label: string }, index: number) => {
          return (
            <Link
              className="nav__link"
              activeClassName="nav__link--current"
              key={index}
              to={item.url}
              state={{ current: item.label }}
            >
              <span className="nav__link-label">{item.label}</span>
            </Link>
          )
        })}
      </nav>
    </aside>
  )
}
